<template>
  <!-- 平台协议管理 -->
  <div class="container">
    <a-page-header :title="title" />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">协议列表</div>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1400, y: true }"
      >
        <template slot="type" slot-scope="record">
          {{ types[record.type] }}
        </template>
        <template slot="location" slot-scope="record">
          <span
            class="location"
            v-for="(item, index) in record.location"
            :key="index"
          >
            {{ item }}
          </span>
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link">
            <router-link
              :to="{
                path: '/agreementAdd',
                query: { id: record.id, type: 'info' }
              }"
            >
              协议详情
            </router-link>
          </a-button>
          <a-button type="link">
            <router-link
              :to="{
                path: '/agreementAdd',
                query: { id: record.id, type: 'change' }
              }"
            >
              发布新版
            </router-link>
          </a-button>
          <a-button type="link">
            <router-link
              :to="{
                path: '/agreementAdd',
                query: { id: record.id }
              }"
            >
              历史版本
            </router-link>
          </a-button>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '协议类型',
          width: 100,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '协议名称',
          width: 200,
          dataIndex: 'name'
        },
        {
          title: '协议/合同编号',
          width: 200,
          dataIndex: 'number'
        },
        {
          title: '最新版本编号',
          width: 150,
          dataIndex: 'version'
        },
        {
          title: '签署对象',
          width: 200,
          scopedSlots: { customRender: 'location' }
        },
        {
          title: '更新时间',
          dataIndex: 'createAt'
        },
        {
          title: '操作',
          width: 300,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      types: {
        1: '服务协议',
        2: '授权协议',
        3: '隐私协议'
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getAgreement()
  },
  methods: {
    getAgreement() {
      this.$axios.getAgreement().then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.location:not(:last-child)::after {
  content: '、';
}
</style>
