var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"main-content"},[_vm._m(0),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"loading":_vm.loading,"scroll":{ x: 1400, y: true }},scopedSlots:_vm._u([{key:"type",fn:function(record){return [_vm._v(" "+_vm._s(_vm.types[record.type])+" ")]}},{key:"location",fn:function(record){return _vm._l((record.location),function(item,index){return _c('span',{key:index,staticClass:"location"},[_vm._v(" "+_vm._s(item)+" ")])})}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"}},[_c('router-link',{attrs:{"to":{
              path: '/agreementAdd',
              query: { id: record.id, type: 'info' }
            }}},[_vm._v(" 协议详情 ")])],1),_c('a-button',{attrs:{"type":"link"}},[_c('router-link',{attrs:{"to":{
              path: '/agreementAdd',
              query: { id: record.id, type: 'change' }
            }}},[_vm._v(" 发布新版 ")])],1),_c('a-button',{attrs:{"type":"link"}},[_c('router-link',{attrs:{"to":{
              path: '/agreementAdd',
              query: { id: record.id }
            }}},[_vm._v(" 历史版本 ")])],1)]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("协议列表")])])])}]

export { render, staticRenderFns }